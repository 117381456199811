import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import { getUserInfo as _getUserInfo } from '@/api/userCenter';
var user = {
  state: {
    msisdn: '',
    phoneCarrier: '',
    currency: '',
    saleChecked: true,
    token: '',
    loginVisible: false
  },
  mutations: {
    updateToken: function updateToken(state, payload) {
      state.token = payload;
    },
    CHANGE_MSISDN: function CHANGE_MSISDN(state, msisdn) {
      state.msisdn = msisdn;
    },
    CHANGE_PHONECARRIER: function CHANGE_PHONECARRIER(state, phoneCarrier) {
      state.phoneCarrier = phoneCarrier;
    },
    CHANGE_CURRENCY: function CHANGE_CURRENCY(state, currency) {
      state.currency = currency;
    },
    CHANGE_SALECHECKED: function CHANGE_SALECHECKED(state, saleChecked) {
      state.saleChecked = saleChecked;
    },
    changeLoginVisible: function changeLoginVisible(state, payload) {
      state.loginVisible = payload;
    }
    // CHANGE_COUNTRYNAME: (state, countryName) => {
    //   state.countryName = countryName
    // }
  },
  actions: {
    getUserInfo: function getUserInfo(_ref) {
      var commit = _ref.commit;
      _getUserInfo().then(function (_ref2) {
        var result = _ref2.result;
        commit('CHANGE_MSISDN', result.msisdn || window.localStorage.getItem('msisdn'));
        commit('CHANGE_CURRENCY', result.currency);
      }).catch(function (err) {
        _Dialog.alert({
          title: '提示',
          message: err
        });
      });
    },
    setMsisdn: function setMsisdn(_ref3, data) {
      var commit = _ref3.commit;
      commit('CHANGE_MSISDN', data);
    },
    setPhoneCarrier: function setPhoneCarrier(_ref4, data) {
      var commit = _ref4.commit;
      commit('CHANGE_PHONECARRIER', data);
    },
    setSaleChecked: function setSaleChecked(_ref5, data) {
      var commit = _ref5.commit;
      commit('CHANGE_SALECHECKED', data);
    }
  }
};
export default user;