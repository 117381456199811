import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("footer", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm.$route.name === "Home" || _vm.$route.name === "User") && _vm.isLoginUser,
      expression: "($route.name === 'Home' || $route.name === 'User') && isLoginUser"
    }]
  }, [_c("router-link", {
    attrs: {
      tag: "div",
      to: "/home"
    }
  }, [_c("zch-icon", {
    class: {
      active: _vm.$route.name === "Home"
    },
    attrs: {
      icon: "home"
    }
  })], 1), _c("router-link", {
    attrs: {
      tag: "div",
      to: "/user/index"
    }
  }, [_c("zch-icon", {
    class: {
      active: _vm.$route.name === "User"
    },
    attrs: {
      icon: "user"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };