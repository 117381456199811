var getters = {
  msisdn: function msisdn(state) {
    return state.user.msisdn;
  },
  phoneCarrier: function phoneCarrier(state) {
    return state.user.phoneCarrier;
  },
  currency: function currency(state) {
    return state.user.currency;
  },
  saleChecked: function saleChecked(state) {
    return state.user.saleChecked;
  },
  countryName: function countryName(state) {
    return state.user.countryName;
  }
};
export default getters;