import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var router = [{
  path: '/art',
  redirect: '/art/lottery'
}, {
  path: '/art/lottery',
  name: 'ArtLottery',
  component: function component() {
    return import('@/views/ArtCenter/Lottery');
  },
  meta: {
    title: '抽奖'
  }
}, {
  path: '/art/lottery-record',
  name: 'LotteryRecord',
  component: function component() {
    return import('@/views/ArtCenter/LotteryRecord');
  },
  meta: {
    title: '获奖记录'
  }
}];
export default router;