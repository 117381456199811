import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var router = [{
  path: '/',
  redirect: '/home',
  meta: {}
}, {
  path: '/home',
  name: 'Home',
  component: function component() {
    return import('@/views/Home');
  },
  meta: {
    keepAlive: true,
    content: {
      description: '游全球,全球200多个国家的话费流量充值平台.',
      keywords: '游全球,话费充值,流量充值,印尼,菲律宾,越南,马来西亚,缅甸,泰国,东南亚,非洲'
    }
  }
}, {
  path: '/home/order/payment',
  name: 'HomePayment',
  component: function component() {
    return import('@/views/Home/HomePayment');
  },
  meta: {
    title: '微信支付'
  }
}, {
  path: '/home/imageAndTextList',
  name: 'ImageTextAndArticleList',
  component: function component() {
    return import('@/views/Home/components/ImageTextAndArticleList');
  },
  meta: {
    keepAlive: true,
    title: '图文列表'
  }
}, {
  path: '/home/imageTextAndShowPage',
  name: 'ImageTextAndShowPage',
  component: function component() {
    return import('@/views/Home/components/ImageTextAndShowPage');
  },
  meta: {
    keepAlive: true,
    title: '图文详情'
  }
}, {
  path: '/home/:code',
  name: 'Home',
  component: function component() {
    return import('@/views/Home');
  },
  meta: {
    content: {
      keywords: '游全球,话费充值,流量充值,印尼,菲律宾,越南,马来西亚,缅甸,泰国,东南亚,非洲'
    }
  }
}, {
  path: '/home/:code/:carrier',
  name: 'Home',
  component: function component() {
    return import('@/views/Home');
  },
  meta: {
    content: {
      keywords: '游全球,话费充值,流量充值,印尼,菲律宾,越南,马来西亚,缅甸,泰国,东南亚,非洲'
    }
  }
}, {
  path: '/home/:code/:carrier/:type',
  name: 'Home',
  component: function component() {
    return import('@/views/Home');
  },
  meta: {
    content: {
      keywords: '游全球,话费充值,流量充值,印尼,菲律宾,越南,马来西亚,缅甸,泰国,东南亚,非洲'
    }
  }
}, {
  path: '/404',
  name: 'Page404',
  component: function component() {
    return import('@/components/404Page');
  },
  meta: {
    title: '页面找不到了'
  }
}, {
  path: '/403',
  name: 'Page403',
  component: function component() {
    return import('@/components/403Page');
  },
  meta: {
    title: '无权访问'
  }
}, {
  path: '/serveDead',
  name: 'ServeDead',
  component: function component() {
    return import('../../components/ServeDead');
  },
  meta: {
    title: '页面维护中'
  }
}, {
  path: '/leadFollow',
  name: 'LeadFollow',
  component: function component() {
    return import('../../components/LeadFollow');
  },
  meta: {
    title: '未关注'
  }
}, {
  path: '/networkError',
  name: 'NetworkError',
  component: function component() {
    return import('../../components/NetworkError');
  },
  meta: {
    title: '网络错误'
  }
}, {
  path: '/browser',
  name: 'Browser',
  component: function component() {
    return import('../../components/Browser');
  },
  meta: {
    title: '页面维护中'
  }
}, {
  path: '/middlepage',
  name: 'MiddlePage',
  component: function component() {
    return import('../../components/MiddlePage');
  },
  meta: {
    title: '支付宝支付'
  }
}, {
  path: '/wecomPage',
  name: 'wecomPage',
  component: function component() {
    return import('../../components/wecomPage');
  },
  meta: {
    title: '企业微信页面'
  }
}];
export default router;