import _toConsumableArray from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import Vue from 'vue';
import Router from 'vue-router';

/* Router Modules */
import constantRoutes from './modules/constantRoutes.js';
import userCenter from './modules/userCenter.js';
import artCenter from './modules/artCenter.js';
import batch from './modules/batch.js';
Vue.use(Router);
var routes = [].concat(_toConsumableArray(constantRoutes), _toConsumableArray(userCenter), _toConsumableArray(artCenter), _toConsumableArray(batch), [{
  path: '*',
  redirect: '/404'
}]);
export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_FILE_PATH,
  scrollBehavior: function scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  routes: routes
});