var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    staticClass: "icon",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("click", $event);
      }
    }
  }, [_c("use", {
    attrs: {
      "xlink:href": "#" + _vm.icon
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };