import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var router = [{
  path: '/batch',
  redirect: '/batch/recharge',
  meta: {
    keepAlive: true,
    title: '游全球批量充值'
  }
}, {
  path: '/batch/recharge',
  name: 'BatchRecharge',
  component: function component() {
    return import('@/views/Home/BatchRecharge');
  },
  meta: {
    keepAlive: true,
    title: '游全球批量充值'
  }
}, {
  path: '/batch/select_order',
  name: 'BatchRechargeSelect',
  component: function component() {
    return import('@/views/Home/BatchRechargeSelect');
  },
  meta: {
    title: '批量充值查询'
  }
}, {
  path: '/batch/user',
  name: 'BatchUser',
  component: function component() {
    return import('@/views/UserCenter/BatchUser');
  },
  meta: {
    title: '个人中心'
  }
}, {
  path: '/batch/orderDetail',
  name: 'BatchOrderDetail',
  component: function component() {
    return import('@/views/UserCenter/BatchOrderDetail');
  },
  meta: {
    title: '批充详情页'
  }
}];
export default router;