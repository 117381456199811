import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/checkbox-group/style";
import _CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import ZchFooter from "@/components/ZchFooter";
import { isWechatBrowser, isWindowBrowser, isAliPayLife } from "@/utils/index";
import Vue from "vue";
Vue.use(_Checkbox);
Vue.use(_CheckboxGroup);
Vue.use(_Toast);
export default {
  name: "App",
  components: _defineProperty({
    ZchFooter: ZchFooter
  }, _Popup.name, _Popup),
  data: function data() {
    return {
      footerVisible: false,
      checked: false,
      aggrementVisible: false
    };
  },
  created: function created() {
    console.log(this.$store.state, "111");
    this.footerVisible = isWechatBrowser && !isWindowBrowser || isAliPayLife;
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$route.meta.keepAlive ? ['Home', 'User'] : [];
    }
  },
  methods: {
    toAggrement: function toAggrement() {
      this.aggrementVisible = true;
    },
    wxAuthLogin: function wxAuthLogin() {
      if (!this.checked) return _Toast("请勾选协议");
      var appid = "wx3422f429917be190";
      // const redirectUrl = encodeURIComponent(`https://lingxue.kqlink.com${this.$route.path}`)//本地调试用
      var redirectUrl = encodeURIComponent("".concat(process.env.VUE_APP_AUTH_URL).concat(this.$route.path));
      var state = "zch";
      var scope = "snsapi_login";
      console.log(window.location.href);
      console.log(this.$route);
      console.log(this.$store.state);
      this.$store.commit("changeLoginVisible", false);
      window.location.href = "https://open.weixin.qq.com/connect/qrconnect?appid=".concat(appid, "&redirect_uri=").concat(redirectUrl, "&response_type=code&scope=").concat(scope, "&state=").concat(state, "#wechat_redirect");
    },
    aliAuthLogin: function aliAuthLogin() {
      if (!this.checked) return _Toast("请勾选协议");
      var appid = "2016082601805806"; //这个是网页的appid
      // const appid = process.env.VUE_APP_ALI_APPID//生活号appid
      // const redirectUrl = encodeURIComponent(`https://lingxue.kqlink.com${this.$route.path}`)//本地调试用
      var redirectUrl = encodeURIComponent("".concat(process.env.VUE_APP_AUTH_URL).concat(this.$route.path));
      console.log(redirectUrl, "重新跳转的路径。。");
      var scope = "auth_user";
      this.$store.commit("changeLoginVisible", false);
      window.location.href = "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=".concat(appid, "&scope=").concat(scope, "&redirect_uri=").concat(redirectUrl, "&state=zch");
    },
    gobackHome: function gobackHome() {
      this.$store.commit("changeLoginVisible", false);
      if (this.$route.path === "/batch/user") {
        this.$router.push("/batch/recharge");
      } else {
        this.$router.push("/home");
      }
    }
  }
};