import requestWithLoading from '@/utils/requestWithLoading';
export function getCountryList(params) {
  return requestWithLoading({
    url: 'wx/country/getAll',
    method: 'get',
    params: params
  });
}
export function getCarrierList(countryCode) {
  return requestWithLoading({
    url: "/wx/carrier/getCarriers/".concat(countryCode),
    method: 'get'
  });
}
export function getUsedCountryList() {
  return requestWithLoading({
    url: 'wx/country/getUserCommonlyUsedCountry',
    method: 'get'
  });
}

//获取微信外部浏览器跳转微信小程序短链，有效期30天，过期需要重新请求
export function getUrlLink(appId, data) {
  return requestWithLoading({
    url: "/wechat/mini/generateUrlLink/".concat(appId),
    method: 'post',
    data: data
  });
}