import "core-js/modules/es.array.concat.js";
import requestWithLoading from '@/utils/requestWithLoading';
export function sendCode(code) {
  var shareCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return requestWithLoading({
    url: "/wx/user/login".concat(code ? "?code=".concat(code, "&shareCode=").concat(shareCode) : ''),
    method: 'POST'
  });
}
export function sendAliCode(code) {
  return requestWithLoading({
    url: "/wx/user/alipayLive/login?code=".concat(code),
    method: 'POST'
  });
}
export function sendAliAuth(code, appId, scope) {
  // alert('授权接口触发:' + code)
  return requestWithLoading({
    url: "/wx/user/alipayLive/auth?code=".concat(code, "&appId=").concat(appId, "&scope=").concat(scope),
    method: 'POST'
  });
}
export function getBannerList(key) {
  return requestWithLoading({
    url: "/banner/list/".concat(key),
    method: 'get'
  });
}
export function getUserCouponList() {
  return requestWithLoading({
    url: '/wx/coupon/getUserCoupon',
    method: 'get'
  });
}
export function wxWebLogin(code, appId) {
  return requestWithLoading({
    url: "/wx/user/web/login?code=".concat(code, "&appId=").concat(appId),
    method: 'post'
  });
}
export function orderPayAgain(data) {
  return requestWithLoading({
    url: '/wx/charge/again/pay',
    method: 'post',
    data: data
  });
}