import requestWithLoading from '@/utils/requestWithLoading';
export function getUserInfo() {
  return requestWithLoading({
    url: 'wx/user/account/info',
    method: 'get'
  });
}
export function getCheckInfo() {
  return requestWithLoading({
    url: 'wx/activity/getDailyCheckInfo',
    method: 'get'
  });
}
export function checkIn() {
  return requestWithLoading({
    url: 'wx/activity/doCheckIn',
    method: 'post'
  });
}
export function getCouponList(data) {
  return requestWithLoading({
    url: 'wx/coupon/page/getUserCoupon',
    method: 'post',
    data: data
  });
}
export function getRechargeRecord(data) {
  return requestWithLoading({
    url: 'wx/order/user',
    method: 'post',
    data: data
  });
}
export function getRechargeRecordDetail(yqqNo) {
  return requestWithLoading({
    url: "wx/order/detail/".concat(yqqNo),
    method: 'get'
  });
}